import React from 'react'
import { Helmet } from 'react-helmet'
import { Theme } from '@sonika-se/ui-kit'
import { createUseStyles } from 'react-jss'

import Layout from '../components/Layout'

const useStyles = createUseStyles<Theme, 'main' | 'h1' | 'h2' | 'p'>({
  main: {
    margin: '0 auto',
    maxWidth: '40rem',
    padding: '2rem 0'
  },
  h1: {
    fontSize: '2rem',
    fontWeight: 'bold',
    margin: '0 0 32px'
  },
  h2: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    margin: '20px 0 10px'
  },
  p: {
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: '1.25',
    margin: '0 0 15px'
  }
})

export default function PrivacyPolicyPage() {
  const classes = useStyles()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="description" content="Nästa generations plattform för personal, schemaläggning, tidrapportering." />
        <link href="/assets/icons/sonika.ico" rel="icon" type="image/x-icon" />
        <link href="/assets/icons/sonika-32x32.png" rel="icon" sizes="32x32" type="image/png" />
        <link href="/assets/icons/sonika-180x180.png" rel="icon" sizes="180x180" type="image/png" />
        <link href="/assets/icons/sonika-192x192.png" rel="icon" sizes="192x192" type="image/png" />
        <link href="/assets/icons/sonika-512x512.png" rel="icon" sizes="512x512" type="image/png" />
        <title>Sonika - Personuppgiftspolicy</title>
      </Helmet>
      <Layout>
        <div className={classes.main}>
          <h1 className={classes.h1}>Personuppgiftspolicy för Sonika</h1>
          <h2 className={classes.h2}>Vi behandlar dina uppgifter som proffs</h2>
          <p className={classes.p}>I maj 2018 trädde den nya Dataskyddsförordningen, GDPR, i kraft. Lagstiftningen syftar till att stärka skyddet för dina personuppgifter och vi vill informera dig om hur vi hanterar dina personuppgifter.</p>
          <p className={classes.p}>För oss är det viktigt att du kan känna dig trygg. Därför vill vi hantera dina personuppgifter på ett bra sätt och vara transparenta och öppna med hur vi gör. Vi kommer kontinuerligt att arbeta med att utveckla och stärka skyddet för dina personuppgifter.</p>
          <p className={classes.p}>Du hittar mer information om den nya dataskyddslagstiftningen på Integritetsskyddsmyndighetens webbplats, <a href="https://imy.se" target="_blank">imy.se</a>.</p>
          <h2 className={classes.h2}>Information kopplat till din användare</h2>
          <p className={classes.p}>Eftersom du är anställd hos en kund till Sonika AB så behandlar vi dina personuppgifter. Personuppgifter är uppgifter som går att koppla till en specifik individ t.ex. namn, personnummer, kontaktuppgifter m.m. Personuppgiftsansvarig för behandlingen är Sonika AB, org.nr 556816-4551. Du kan nå oss på <a href="mailto:info@sonika.se">info@sonika.se</a></p>
          <p className={classes.p}>För oss är det viktigt att du känner dig trygg med hur vi behandlar dina personuppgifter och därför strävar vi alltid efter att göra vårt yttersta för att säkerställa att dina personuppgifter är skyddade. För att göra det arbetar vi med en rad olika åtgärder. Vi har bland annat utsett ett dataskyddsombud vars främsta uppgift är att granska att vi följer gällande lagar, regler och interna policys och riktlinjer.</p>
          <p className={classes.p}>Vi får dina uppgifter huvudsakligen direkt från din arbetsgivare och dig själv. Din arbetsgivare lämnar t.ex. uppgifter till oss när ditt konto skapas i Sonika. Därtill genereras uppgifter också under din tid i Sonika t.ex. vid schemaläggning, tidrapportering, och dokumentation. Vi får ibland också uppgifter från myndigheter, t.ex. om du är föremål för utmätning eller liknande.</p>
          <p className={classes.p}>Vi behandlar namn, personnummer, kontaktuppgifter, arbetsschema, lön, attester, semester, sjukskrivningar och VAB-dagar. Vi behandlar också foton av dig, men bara om du själv valt att lägga in dem t.ex. som profilbild eller kopplat till kunskapsbanken.</p>
          <h2 className={classes.h2}>Skyddsåtgärder för dina personuppgifter</h2>
          <p className={classes.p}>Vi har vidtagit olika tekniska och organisatoriska skyddsåtgärder för att skydda dina personuppgifter mot oavsiktlig eller olaglig förstöring, förlust, ändring, obehörig delning, obehörig tillgång till samt andra olagliga eller obehöriga sätt att behandla dina personuppgifter. Vi ska arbeta enligt gällande policys och riktlinjer och tillämpar rutiner och system för att skydda dina personuppgifter mot obehörig åtkomst. Våra system är även skyddade för otillbörlig åtkomst av bl.a. brandväggar och kryptering. Personuppgifter sparade i dokument och som behandlas manuellt förvaras i låsta lokaler med begränsad access och ska endast användas av anställda som måste ha tillgång till dem för att kunna utföra sina arbetsuppgifter.</p>
          <p className={classes.p}>Du har rätt att få information om vilka personuppgifter som finns registrerade om dig själv hos oss, utan kostnad. Eftersom vi måste säkerställa att dina uppgifter inte skickas till någon annan som utger sig för att vara du måste vi vidta vissa säkerhetsåtgärder för att identifiera dig.</p>
          <p className={classes.p}>Sonika AB<br />BOX 11255<br />100 61 Stockholm<br /><a href="mailto:info@sonika.se">info@sonika.se</a></p>
        </div>
      </Layout>
    </>
  )
}